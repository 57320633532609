import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { actionUpdateProfileData } from '@lib/core/users/slices/profile';
import { PRODUCT_CATEGORY_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { birthYearValidation } from '@lib/tools/shared/helpers/utils';
import { PAGES } from '@lib/tools/views/urls';

import { ICheckboxAnswer } from '@components/web/src/atoms/Checkboxes&Radio/CheckboxAnswer/CheckboxAnswer';
import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import { AgePage } from '@components/web/src/templates/TestFlowsPages/AgePage/AgePage';
import { GenderPage } from '@components/web/src/templates/TestFlowsPages/GenderPage/GenderPage';

enum Page {
  AGE = 'AGE',
  GENDER = 'GENDER',
}

const AgeAndGenderContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const productCategoryUrlParam: any = searchParams.get(PRODUCT_CATEGORY_URL_PARAM) || '';

  const { profileGenderSlug, profileBirthYear, profileGenderOptions, isProfileLoading } = useUser();

  const [page, setPage] = useState<Page>(Page.GENDER);
  const [genderOptions, setGenderOptions] = useState<ICheckboxAnswer[]>([]);
  const [birthYear, setBirthYear] = useState(profileBirthYear?.toString() || '');

  useEffect(() => {
    setGenderOptions(
      profileGenderOptions?.map(option => ({
        ...option,
        isSelected: option.slug === profileGenderSlug,
      })),
    );
  }, [profileGenderOptions, profileGenderSlug]);

  const { maxValidBirthYear, isValidBirthYear } = birthYearValidation(birthYear);

  const isDisabledGenderContinueBtn = !genderOptions.some(data => data.isSelected);

  const handleGenderOptionClick = (slug: string) => {
    const updatedGenderOptions = genderOptions.map(data =>
      data.slug === slug ? { ...data, isSelected: !data.isSelected } : { ...data, isSelected: false },
    );

    setGenderOptions(updatedGenderOptions);
  };

  const handleBirthYearChange = (value: string) => setBirthYear(value);

  const handleGenderContinueClick = () => {
    const selectedGenderOptionSlug = genderOptions.filter(data => data.isSelected).map(data => data.slug)[0];

    dispatch(actionUpdateProfileData({ gender: selectedGenderOptionSlug }));
    setPage(Page.AGE);
  };

  const handleAgeContinueClick = () => {
    dispatch(actionUpdateProfileData({ birth_year: Number(birthYear) }));
    navigate(
      prependBasename(PAGES.vinhood.quiz.chooseExpert, {
        [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam,
      }),
    );
  };

  const handleSkipClick = () =>
    page === Page.GENDER
      ? setPage(Page.AGE)
      : navigate(
          prependBasename(PAGES.vinhood.quiz.chooseExpert, {
            [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam,
          }),
        );

  const handleGoBackClick = () =>
    page === Page.GENDER
      ? navigate(
          prependBasename(PAGES.vinhood.home, {
            [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam,
          }),
        )
      : setPage(Page.GENDER);

  const pageView =
    page === Page.GENDER ? (
      <GenderPage
        genderOptions={genderOptions}
        handleContinueClick={handleGenderContinueClick}
        handleGenderOptionClick={handleGenderOptionClick}
        handleGoBack={() => handleGoBackClick()}
        handleSkipClick={() => handleSkipClick()}
        isDisabledBtn={isDisabledGenderContinueBtn}
      />
    ) : (
      <AgePage
        birthYear={birthYear}
        handleBirthYearChange={handleBirthYearChange}
        handleContinueClick={handleAgeContinueClick}
        handleGoBack={() => handleGoBackClick()}
        handleSkipClick={() => handleSkipClick()}
        isValidBirthYear={isValidBirthYear}
        maxValidBirthYear={maxValidBirthYear}
      />
    );

  return isProfileLoading ? <LoadingSpinner variant="spinner" /> : pageView;
};

export default AgeAndGenderContainer;
