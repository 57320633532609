import { Dispatch, FC, SetStateAction } from 'react';

import { IAuthFeatureParams } from '@app/web/src/hooks/useAuthFeatureClick';

import { AUTH_BANNERS } from '@lib/core/banners/consts';
import { TCharacter } from '@lib/core/characters/types';
import { IComment, ICommentHandleSubmit } from '@lib/core/comments/types';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';

import { ICharacterAdjectiveItemData } from '@components/web/src/atoms/CharacterAdjective/CharacterAdjective';
import { FooterAppAnonymous } from '@components/web/src/atoms/Footers';
import RegistrationBanner from '@components/web/src/components/Authentication/RegistrationBanner/RegistrationBanner';
import CharacterAdjectivesGroup from '@components/web/src/organisms/CharacterAdjectivesGroup/CharacterAdjectivesGroup';
import TastePathGuide from '@components/web/src/organisms/TastePathGuide/TastePathGuide';
import LearnMoreSection from '@components/web/src/organisms/TestResult/LearnMoreSection/LearnMoreSection';
import ProductSection from '@components/web/src/organisms/TestResult/ProductSection/ProductSection';
import QuizSection, { IQuizSectionQuizData } from '@components/web/src/organisms/TestResult/QuizSection/QuizSection';
import * as S from '@components/web/src/templates/ResultPages/TestResultPage/styles';

interface IProps {
  locale: string;
  storeType: TRetailerLocationStoreType;
  productInstanceData: TProductInstance;
  productCategory: TProductCategory;
  characterData: TCharacter;
  quizData: IQuizSectionQuizData[];
  characterAdjectiveItemsData: ICharacterAdjectiveItemData[];
  retailerLocationName?: string;
  isDesignSetVinhoodExperience?: boolean;
  isLoadingProduct?: boolean;
  isEnableTastePathAddon?: boolean;
  isCommentsLoaded?: boolean;
  commentsList?: IComment[];
  isNewCommentsDataFetching?: boolean;
  handleUpdateWishlistProductList?: (productId: string) => void;
  handleAuthFeatureClick?: (params?: IAuthFeatureParams) => void;
  handleProductCardClick?: () => void;
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
}

const TestResultPage: FC<IProps> = ({
  locale,
  storeType,
  productInstanceData,
  characterData,
  quizData,
  productCategory,
  characterAdjectiveItemsData,
  retailerLocationName,
  isDesignSetVinhoodExperience = false,
  isLoadingProduct = false,
  isEnableTastePathAddon = false,
  isCommentsLoaded,
  commentsList,
  isNewCommentsDataFetching,
  handleAuthFeatureClick,
  handleUpdateWishlistProductList,
  handleProductCardClick,
  handleSubmitComment,
  setIsNewCommentsDataFetching,
}) => {
  return (
    <S.TestResultPageContainer direction="column">
      <CharacterAdjectivesGroup
        isTestResultPage
        characterAdjectiveItemsData={characterAdjectiveItemsData}
        characterData={characterData}
        handleAdjectiveClick={handleAuthFeatureClick}
        productCategory={productCategory}
      />
      {isEnableTastePathAddon ? (
        <TastePathGuide isResultPage characterName={characterData.name} />
      ) : (
        <>
          {(isLoadingProduct || !!productInstanceData) && (
            <ProductSection
              commentsList={commentsList}
              handleAuthFeatureClick={handleAuthFeatureClick}
              handleProductCardClick={handleProductCardClick}
              handleSubmitComment={handleSubmitComment}
              handleUpdateWishlistProductList={handleUpdateWishlistProductList}
              isCommentsLoaded={isCommentsLoaded}
              isLoadingProduct={isLoadingProduct}
              isNewCommentsDataFetching={isNewCommentsDataFetching}
              locale={locale}
              productCategory={productCategory}
              productInstanceData={productInstanceData}
              retailerLocationName={retailerLocationName}
              setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
              storeType={storeType}
            />
          )}
          <QuizSection
            characterData={characterData}
            handleQuizSectionClick={handleAuthFeatureClick}
            quizData={quizData}
          />
          <LearnMoreSection
            characterData={characterData}
            handleLearnMoreSectionClick={handleAuthFeatureClick}
            productCategory={productCategory}
          />
        </>
      )}
      {!isDesignSetVinhoodExperience && <FooterAppAnonymous showGearIcon />}
      <S.BannerWrapper>
        <RegistrationBanner
          handleNavigateToRegistrationPage={() =>
            handleAuthFeatureClick({ registrationBannerVariant: AUTH_BANNERS.click })
          }
        />
      </S.BannerWrapper>
    </S.TestResultPageContainer>
  );
};

export default TestResultPage;
