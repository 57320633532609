import { TokenResponse } from '@react-oauth/google';
import { FC } from 'react';
import { AppleLoginProps } from 'react-apple-login';
import { animated, useTransition } from 'react-spring';

import { IRegistrationBannersData } from '@app/web/src/hooks/useRegistrationBannersData';

import { IParsedServiceTerm, IRegisterPageServiceTerms } from '@lib/core/serviceTerms/types';
import { ISocialLoginFacebook } from '@lib/tools/views/web/interfaces/loginSocial';

import RegistrationNavigation from '@components/web/src/components/Authentication/Registration/RegistrationNavigation/RegistrationNavigation';
import RegistrationBanner from '@components/web/src/organisms/Authentication/AuthBanners/RegistrationBanner/RegistrationBanner';
import EmailRegistrationForm from '@components/web/src/organisms/Authentication/EmailRegistration/RegistrationEmailForm';
import SocialConnector from '@components/web/src/organisms/Authentication/SocialConnector/SocialConnector';
import { RegistrationFormInputObject } from '@components/web/src/pages/Authentication/AuthFormInterface';
import * as S from '@components/web/src/templates/Login&Registration/LoginPage/styles';

interface IProps {
  isServiceInstanceFeatureEnabled: boolean;
  isDesignSetVinhoodExperience: boolean;
  isEmailAuthorization: boolean;
  authErrors: string;
  isSocialAuthTesting?: boolean;
  isHideSkipAndGoToProductsAddon: boolean;
  registrationBannersData?: IRegistrationBannersData;
  registerPageServiceTerms: IRegisterPageServiceTerms;
  onEmailAuthButtonClick: () => void;
  navigateToLoginPage: () => void;
  navigateToCatalog: () => void;
  handleOnServiceTermToggle: (term: IParsedServiceTerm) => void;
  handleFormSubmit: (data: RegistrationFormInputObject) => void;
  responseFacebook: (response: ISocialLoginFacebook) => void;
  responseApple: (response: AppleLoginProps) => void;
  responseGoogle: (response: TokenResponse) => void;
  onBackButtonClick?: () => void;
}

const RegistrationPage: FC<IProps> = ({
  isDesignSetVinhoodExperience,
  isEmailAuthorization,
  authErrors,
  isServiceInstanceFeatureEnabled,
  isHideSkipAndGoToProductsAddon,
  registrationBannersData,
  isSocialAuthTesting,
  registerPageServiceTerms,
  onEmailAuthButtonClick,
  navigateToLoginPage,
  navigateToCatalog,
  responseGoogle,
  responseFacebook,
  responseApple,
  handleFormSubmit,
  handleOnServiceTermToggle,
  onBackButtonClick,
}) => {
  const transitions = useTransition(isEmailAuthorization, {
    config: {
      duration: 300,
    },
    enter: {
      opacity: 1,
      y: '0%',
    },
    from: { opacity: 0, y: '-10%' },
    leave: { opacity: 0, y: '-8%' },
  });

  return (
    <S.AuthPageContainer>
      <S.StyledBackButton handleClick={onBackButtonClick} />
      <RegistrationBanner registrationBannersData={registrationBannersData} />
      <S.Content>
        <SocialConnector
          isRegistrationPage
          authErrors={authErrors}
          handleEmailAuthButtonClick={onEmailAuthButtonClick}
          isEmailAuthorization={isEmailAuthorization}
          isSocialAuthTesting={isSocialAuthTesting}
          responseApple={responseApple}
          responseFacebook={responseFacebook}
          responseGoogle={responseGoogle}
        />
        {transitions((style, isOpen) => (
          <>
            {isOpen && (
              <animated.div style={style}>
                <EmailRegistrationForm
                  handleFormSubmit={handleFormSubmit}
                  handleOnServiceTermToggle={handleOnServiceTermToggle}
                  registerPageServiceTerms={registerPageServiceTerms}
                />
              </animated.div>
            )}
          </>
        ))}
        <RegistrationNavigation
          navigateToCatalog={navigateToCatalog}
          navigateToLoginPage={navigateToLoginPage}
          shouldDisplayCatalogLink={
            (isDesignSetVinhoodExperience && !isHideSkipAndGoToProductsAddon) || isServiceInstanceFeatureEnabled
          }
        />
      </S.Content>
    </S.AuthPageContainer>
  );
};

export default RegistrationPage;
