import { FC, ReactNode, RefObject } from 'react';

import { TCharacter } from '@lib/core/characters/types';

import CharacterDetailsSkeleton from '@components/web/src/components/Skeleton/CharacterDetailsSkeleton/CharacterDetailsSkeleton';
import CharacterInfoComponent from '@components/web/src/organisms/CharacterInfo/CharacterInfo';
import * as S from '@components/web/src/pages/widget/Catalog/styles';

interface ICatalogWrapperProps {
  isShowFullHeight: boolean;
  isShowCharacterDetailsSkeleton: boolean;
  isShowCharacterDetailsComponent: boolean;
  characterDetailsRef: RefObject<HTMLDivElement>;
  characterDetailsHeight: number;
  isScrolled: boolean;
  character: TCharacter;
  locale: string;
  userFirstName: string;
  productCategory: string;
  children: ReactNode;
}

const CatalogWrapper: FC<ICatalogWrapperProps> = ({
  isShowFullHeight,
  isShowCharacterDetailsSkeleton,
  isShowCharacterDetailsComponent,
  characterDetailsRef,
  characterDetailsHeight,
  isScrolled,
  character,
  locale,
  userFirstName,
  productCategory,
  children,
}) => (
  <S.CatalogContainer $isShowFullHeight={isShowFullHeight}>
    {isShowCharacterDetailsSkeleton && <CharacterDetailsSkeleton productCategory={productCategory} />}
    {isShowCharacterDetailsComponent && (
      <S.CharacterDetailsWrapper $height={isScrolled ? characterDetailsHeight : 116}>
        <CharacterInfoComponent
          ref={characterDetailsRef}
          character={character}
          locale={locale}
          userFirstName={userFirstName}
        />
      </S.CharacterDetailsWrapper>
    )}
    {children}
  </S.CatalogContainer>
);

export default CatalogWrapper;
