import moment from 'moment';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ARTICLE_ID_URL_PARAM } from '@app/web/src/helpers/consts';

import { fetchArticleLists } from '@lib/core/articles/slices/articleCatalogSlice';
import { BANNER_POSITION_TIP_AND_TRICKS } from '@lib/core/banners/consts';
import { useBanners } from '@lib/core/banners/hooks';
import { fetchBannersLists } from '@lib/core/banners/slices/bannersSlice';
import { useQuizzes } from '@lib/core/quizzes/hooks';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import { selectRegisteredUserNotFinishedOnboarding } from '@lib/core/service/selectors/technical';
import { prependBasename } from '@lib/core/service/utils';
import { useDietaryPreferences, useProductFeedback, useProductList, useUser } from '@lib/core/users/hooks';
import UserUtils from '@lib/core/users/utils/users';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { useProductsSwiper } from '@lib/tools/productsSwiper/hooks/useProductsSwiper';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { getUniqueItemForToday } from '@lib/tools/shared/helpers/utils';
import { useTypedSelector } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import HomePage from '@components/web/src/templates/HomePages/App/HomePage/HomePage';
import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';

const HomeContainer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { handleFetchSwiperProducts } = useProductsSwiper();
  const { locale } = useApp();
  const {
    userBestMatchCharacterByProductCategory,
    isUserDataLoading,
    userDataRaw: userData,
    isUserHasAnyCharacter,
  } = useUser();
  const { retailerTags } = useRetailer();
  const { retailerStoreType } = useRetailerLocation();
  const { isQuizzesFetching } = useQuizzes();
  const { bannersFetchSuccess } = useTypedSelector(state => state.b2cBanners);
  const { results: articlesList } = useTypedSelector(state => state.articleCatalog.articlesData);
  const { previousRoute } = useApp();
  const hasRegisteredUserNotFinishedOnboarding = useSelector(selectRegisteredUserNotFinishedOnboarding);

  const { feedbackData, handleUpdateFeedback } = useProductFeedback();
  const { wishlistProductInstanceIds, isWishlistProductListLoading, handleUpdateWishlistProductList } =
    useProductList();
  const { userDietaryPreferencePreferencesSlugs, userDietaryPreferenceTagsSlugs, isUserDietaryPreferenceDataLoading } =
    useDietaryPreferences();

  const { discoveryQuiz } = useDiscoveryQuizData();

  const isPassedEvent = retailerTags?.homeLinkEndDate
    ? moment().isAfter(moment(retailerTags?.homeLinkEndDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), 'day')
    : false;
  const shouldDisplayEventSection = !isPassedEvent && !!retailerTags?.homeLink;

  const [isQrScannerModalOpen, setIsQrScannerModalOpen] = useState(false);

  const { profilePercent, isTagsDataCompleted } = UserUtils.isUserInformationComplete({
    userData,
    userDietaryPreferencePreferencesSlugs,
    userDietaryPreferenceTagsSlugs,
  });

  const hintBanner = useBanners({
    bannerPosition: BANNER_POSITION_TIP_AND_TRICKS,
    randomNumberOfBanners: 1,
    returnAllBanners: false,
  });

  const articleData = useMemo(() => {
    if (articlesList.length) {
      return getUniqueItemForToday({ itemArray: articlesList })[0];
    }
    return null;
  }, [articlesList]);

  const situationalCardsData = [PRODUCT_CATEGORY_WINE, PRODUCT_CATEGORY_COFFEE, PRODUCT_CATEGORY_BEER];

  useEffect(() => {
    if (hasRegisteredUserNotFinishedOnboarding) {
      navigate(prependBasename(PAGES.vinhood.onboarding));
    }
  }, [hasRegisteredUserNotFinishedOnboarding]);

  useEffect(() => {
    // temporary solution until we can't make request to backend
    dispatch(fetchArticleLists());

    if (!bannersFetchSuccess) {
      dispatch(fetchBannersLists());
    }
  }, [locale]);

  const navigateToEdutainmentList = () => navigate(prependBasename(PAGES.vinhood.edutainment));

  const navigateToHintPage = () => navigate(prependBasename(PAGES.vinhood.hint));

  const navigateToArticlesCatalog = () => navigate(prependBasename(PAGES.vinhood.articles));

  const onCharacterItemSectionClick = () =>
    navigate(prependBasename(isUserHasAnyCharacter ? PAGES.vinhood.tasteId.tasteId : PAGES.vinhood.quiz.chooseTaste));

  const navigateToTagsPage = () => navigate(prependBasename(PAGES.vinhood.tasteId.tasteIdTags));
  const navigateToSettingsPage = () => navigate(prependBasename(PAGES.vinhood.tasteId.tasteIdSettings));
  const navigateToArticlePage = articleId =>
    navigate(prependBasename(PAGES.vinhood.article, { [ARTICLE_ID_URL_PARAM]: articleId }), {
      state: { previousPage: pathname + search },
    });

  const handleEventClick = link => window.open(link);

  const isLoading =
    isUserDataLoading || isQuizzesFetching || !bannersFetchSuccess || isUserDietaryPreferenceDataLoading;

  return isLoading && previousRoute?.includes(PAGES.vinhood.quiz.multiProduct) ? (
    <LoadingSpinner />
  ) : (
    <>
      <HomePage
        articleData={articleData}
        discoveryQuiz={discoveryQuiz}
        feedbackData={feedbackData}
        handleEventClick={handleEventClick}
        handleFetchSwiperProducts={handleFetchSwiperProducts}
        handleUpdateFeedback={handleUpdateFeedback}
        handleUpdateWishlistProductList={handleUpdateWishlistProductList}
        hintBanner={hintBanner}
        isLoading={isLoading}
        isQrScannerModalOpen={isQrScannerModalOpen}
        isTagsDataCompleted={isTagsDataCompleted}
        isUserHasAnyCharacter={isUserHasAnyCharacter}
        isWishlistProductListLoading={isWishlistProductListLoading}
        locale={locale}
        navigate={navigate}
        navigateToArticlePage={navigateToArticlePage}
        navigateToArticlesCatalog={navigateToArticlesCatalog}
        navigateToEdutainmentList={navigateToEdutainmentList}
        navigateToHintPage={navigateToHintPage}
        navigateToSettingsPage={navigateToSettingsPage}
        navigateToTagsPage={navigateToTagsPage}
        profilePercent={profilePercent}
        retailerTags={retailerTags}
        setIsQrScannerModalOpen={setIsQrScannerModalOpen}
        shouldDisplayEventSection={shouldDisplayEventSection}
        situationalCardsData={situationalCardsData}
        storeType={retailerStoreType}
        userBestMatchCharacterByProductCategory={userBestMatchCharacterByProductCategory}
        wishlistProductInstanceIds={wishlistProductInstanceIds}
        onCharacterItemSectionClick={onCharacterItemSectionClick}
      />
    </>
  );
};

export default HomeContainer;
