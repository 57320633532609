import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setShowTastePathStartPage } from '@lib/core/service/slices';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { selectIsProductFeedbackLoading } from '@lib/core/users/selectors/productFeedback';
import { PAGES } from '@lib/tools/views/urls';

import TastePathStartPage from '@components/web/src/pages/TastePathStartPage/TastePathStartPage';

const TastePathStartPageContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFeedbackLoading = useSelector(selectIsProductFeedbackLoading);
  const { userBestMatchCharacterForCurrentServiceProductCategory } = useUser();

  const onHandleClick = () => navigate(prependBasename(PAGES.vinhood.catalog));

  useEffect(() => {
    dispatch(setShowTastePathStartPage(false));
  }, []);

  return (
    <TastePathStartPage
      characterName={userBestMatchCharacterForCurrentServiceProductCategory?.name}
      isDisabledBtn={isFeedbackLoading}
      onHandleClick={onHandleClick}
    />
  );
};

export default TastePathStartPageContainer;
