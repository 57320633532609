import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/LegalPages/GeneralServiceTermsPage/styles';
import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import { GeneralTermsCheckboxesComponent } from '@components/web/src/templates/Modals/GeneralTermsCheckboxesComponent/GeneralTermsCheckboxesComponent';

type Props = {
  generalServiceTermsState: any;
  isLoading?: boolean;
  handleSaveGeneralServiceTerms: () => void;
  handleGoBack: () => void;
  handleOnServiceTermToggle: (value: any) => void;
};

const GeneralServiceTermsPage: FC<Props> = ({
  isLoading,
  handleSaveGeneralServiceTerms,
  handleGoBack,
  handleOnServiceTermToggle,
  generalServiceTermsState,
}) => {
  const {
    generalServiceTermsModal: { headerTitle, headerDescription, cookieOptionTitle, btnConfirm },
  } = localeCommon;

  return (
    <S.GeneralServiceTermsContainer align="flex-start" direction="column" gap={0}>
      <S.StyledBackButton handleClick={handleGoBack} />
      <S.HeadingContentWrapper direction="column" gap={16}>
        <Text fontFamily="Fraunces" size="h6" text={headerTitle} weight="semibold" />
        <Text size="body1" text={headerDescription} />
      </S.HeadingContentWrapper>
      <S.BottomSectionWrapper className="bottom-section" direction="column" gap={16}>
        <Text fontFamily="Fraunces" size="h6" text={cookieOptionTitle} weight="semibold" />
        <GeneralTermsCheckboxesComponent options={generalServiceTermsState} onToggle={handleOnServiceTermToggle} />
        <Button handleClick={handleSaveGeneralServiceTerms} size="sm" text={btnConfirm} variant={VH_VARIANTS.PRIMARY} />
      </S.BottomSectionWrapper>
      {isLoading && <LoadingSpinner variant="spinner" />}
    </S.GeneralServiceTermsContainer>
  );
};

export default GeneralServiceTermsPage;
