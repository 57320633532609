import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useApp } from '@lib/core/service/hooks';
import { useUser } from '@lib/core/users/hooks';
import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import { useProductCatalog } from '@lib/tools/productCatalog/hooks/useProductCatalog';
import { CHARACTERS_URL_PARAM } from '@lib/tools/shared/helpers/consts';

import CatalogWrapper from '@components/web/src/pages/widget/Catalog/CatalogWrapper';

const CatalogContainerHOC = ({ children }) => {
  const { isUserDataLoading, userFirstName } = useUser();

  const [isScrolled, setIsScrolled] = useState(false);
  const [characterDetailsHeight, setCharacterDetailsHeight] = useState(0);
  const touchStartYRef = useRef(0);
  const characterDetailsRef = useRef<HTMLDivElement>(null);

  const { locale } = useApp();
  const { productCategory } = useApp();

  const [searchParams] = useSearchParams();

  const characterStringFromUrl: string | null = searchParams.get(CHARACTERS_URL_PARAM);
  const { userBestMatchCharacterForCurrentServiceProductCategory } = useUser();
  const { isFilterOpened } = useCatalogFilter();
  const { isProductsRequestLoading, products, staticMatchedProducts, isAnyProductsDataLimitedAvailable } =
    useProductCatalog();

  const isShowCharacterDetailsSkeleton = isUserDataLoading && !characterStringFromUrl;
  const isShowCharacterDetailsComponent =
    !isUserDataLoading &&
    !isFilterOpened &&
    !characterStringFromUrl &&
    !!userBestMatchCharacterForCurrentServiceProductCategory;

  useEffect(() => {
    const container = document.getElementById('root-scroll-element');

    const handleWheel = event => {
      if (event.deltaY < 0) {
        setIsScrolled(true);
      }
    };

    const handleTouchMove = event => {
      const touchCurrentY = event.touches[0].clientY;
      if (touchCurrentY > touchStartYRef.current) {
        setIsScrolled(true);
      }
    };
    container?.addEventListener('wheel', handleWheel);
    container?.addEventListener('touchmove', handleTouchMove);

    return () => {
      container?.removeEventListener('wheel', handleWheel);
      container?.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  useEffect(() => {
    if (characterDetailsRef.current) {
      setCharacterDetailsHeight(characterDetailsRef.current.clientHeight);
    }
  }, [isScrolled]);

  const noProductsAvailable =
    !products.length &&
    !staticMatchedProducts.length &&
    !isAnyProductsDataLimitedAvailable &&
    !isProductsRequestLoading;

  const isShowFullHeight = isProductsRequestLoading || noProductsAvailable;

  return (
    <CatalogWrapper
      character={userBestMatchCharacterForCurrentServiceProductCategory}
      characterDetailsHeight={characterDetailsHeight}
      characterDetailsRef={characterDetailsRef}
      isScrolled={isScrolled}
      isShowCharacterDetailsComponent={isShowCharacterDetailsComponent}
      isShowCharacterDetailsSkeleton={isShowCharacterDetailsSkeleton}
      isShowFullHeight={isShowFullHeight}
      locale={locale}
      productCategory={productCategory}
      userFirstName={userFirstName}
    >
      {children}
    </CatalogWrapper>
  );
};

export default CatalogContainerHOC;
