import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { TCharacter } from '@lib/core/characters/types';
import { parseCharacterDetails } from '@lib/core/characters/utils';
import { prependBasename } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { PAGES } from '@lib/tools/views/urls';

import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/CharacterInfo/styles';

interface IProps {
  locale?: string;
  character?: TCharacter;
  userFirstName: string;
}

const CharacterInfo = forwardRef<HTMLDivElement, IProps>(({ character, userFirstName }, ref) => {
  const navigate = useNavigate();
  const { characterName, characterImage, characterDescription, characterProductCategory, characterId } =
    parseCharacterDetails({
      character,
    });

  const {
    productCategories,
    productCard: { ctaGiveItBtn },
    characterDetailMessages: { userCharacterTitleTextB2B, characterTitleTextB2B },
  } = localeCommon;

  const titleText = userFirstName ? userCharacterTitleTextB2B : characterTitleTextB2B;

  return (
    <S.CharacterDetailsContainer ref={ref} $productCategory={characterProductCategory}>
      <S.CharacterDetailsWrapper direction="row" gap={0}>
        <S.CharacterImageWrapper align="center" direction="column" gap={0} justify="center">
          <S.CharacterImage alt="character-image" height="155px" src={characterImage} width="85px" />
        </S.CharacterImageWrapper>
        <S.CharacterDetailsContent direction="column" gap={0}>
          <S.ProductSummary align="flex-start" direction="column" gap={0}>
            <Text
              localeOptions={{ productCategory: characterProductCategory, userFirstName }}
              size="body2"
              text={titleText}
              weight="normal"
              localeVariables={{
                productCategoryText: productCategories[characterProductCategory],
              }}
            />
            <Text fontFamily="Fraunces" size="h4" text={`#${characterName}`} weight="semibold" />
          </S.ProductSummary>
          <S.CharacterDescriptionWrapper>
            <Text linesLimit={4} size="body1" text={characterDescription} />
          </S.CharacterDescriptionWrapper>

          <S.CtaButton
            type="button"
            onClick={() => {
              MixpanelTracker.events.characterDescriptionClick({ productCharacterID: characterId });
              navigate(prependBasename(PAGES.vinhood.characterDetails));
            }}
          >
            <Text size="body1" text={ctaGiveItBtn} weight="semibold" />
          </S.CtaButton>
        </S.CharacterDetailsContent>
      </S.CharacterDetailsWrapper>
    </S.CharacterDetailsContainer>
  );
});

export default CharacterInfo;
