import styled, { css } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export const CatalogContainer = styled.div<{ $isShowFullHeight: boolean }>`
  position: relative;
  z-index: 1;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  height: ${({ $isShowFullHeight }) => ($isShowFullHeight ? '100%' : 'auto')};

  .registration-banner-container {
    margin-top: auto;
  }

  .rounded-corners {
    position: relative;
    z-index: 3;
    height: 16px;
    background-color: var(--color-secondary-100);
    border-radius: 16px 16px 0 0;
  }

  .filter-component-wrapper {
    background: ${STATIC_COLORS.warmGray[100]};
  }
`;

export const ProductCatalogWrapper = styled.div<{ $isWithExtraPadding?: boolean; $isFilterOpened?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  min-height: 200px;
  background: var(--color-secondary-100);
  overflow-y: auto;
  border-radius: ${({ $isFilterOpened }) => ($isFilterOpened ? '0' : '16px 16px 0 0')};

  ${({ $isWithExtraPadding }) =>
    $isWithExtraPadding &&
    css`
      padding-bottom: 28px;
    `}

  .swiper-wrapper {
    align-self: stretch;
    margin-bottom: 18px;

    .swiper-slide {
      flex-grow: 1;
      height: auto !important;
    }

    > *:last-child {
      margin-inline-end: 16px;
    }
  }
`;

export const StyledText = styled(Text)<{ $isBoldProductsListHeadText?: boolean }>`
  ${({ $isBoldProductsListHeadText }) =>
    $isBoldProductsListHeadText
      ? css`
          padding: 16px 16px 0;
        `
      : css`
          padding: 0 16px;
        `}
`;
export const RegistrationBannerWrapper = styled.div`
  position: sticky;
  bottom: 0;
  margin-top: auto;
  z-index: 9999;
`;

export const SeeAllProductsButtonWrapper = styled.div`
  margin: 32px 16px 16px 16px;
`;

export const CharacterDetailsWrapper = styled.div<{ $height: number }>`
  height: ${({ $height }) => `${$height - 16}px`};
  transition: height 0.3s ease;
`;
