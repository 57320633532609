import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useAuthFeatureClick } from '@app/web/src/hooks/useAuthFeatureClick';
import useTestResultData from '@app/web/src/hooks/useTestResultData';

import { AUTH_BANNERS } from '@lib/core/banners/consts';
import { useCommentsHandler } from '@lib/core/comments/hooks/useCommentsHandler';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import { setShowTastePathStartPage } from '@lib/core/service/slices';
import { useProductList, useUser } from '@lib/core/users/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { PRODUCT_CATEGORY_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';

import { TTabIcon } from '@components/web/src/atoms/Tabs/TabButton/TabButton';
import Tabs, { ITabs } from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import TestResultPage from '@components/web/src/templates/ResultPages/TestResultPage/TestResultPage';

const TestResultPageContainer: FC = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { locale } = useApp();
  const { retailerStoreType, retailerLocationName } = useRetailerLocation();
  const { isDesignSetVinhoodExperience } = useRetailerDesignSet();
  const { isEnableTastePathAddon } = useAddons();

  const { productCategories: localeProductCategories } = localeCommon;

  const [activeTab, setActiveTab] = useState(searchParams.get(PRODUCT_CATEGORY_URL_PARAM));

  const { userBestMatchCharacterByProductCategory } = useUser();

  const {
    productCategories,
    quizData,
    isTestResultDataLoading,
    characterAdjectiveItemsData,
    resultProductInstanceData,
    isLoadingProduct,
  } = useTestResultData();

  useEffect(() => {
    if (activeTab) {
      searchParams.delete(PRODUCT_CATEGORY_URL_PARAM);
      setSearchParams(searchParams);
    }
  }, [activeTab]);

  const { handleUpdateWishlistProductList } = useProductList();
  const { handleAuthFeatureClick } = useAuthFeatureClick();

  const {
    commentsList,
    isCommentsLoaded,
    isNewCommentsDataFetching,
    setIsNewCommentsDataFetching,
    handleSubmitComment,
  } = useCommentsHandler();

  useEffect(() => {
    if (isEnableTastePathAddon) dispatch(setShowTastePathStartPage(false));
  }, []);

  const tabsData: ITabs = useMemo(
    () => ({
      active: activeTab || productCategories[0],
      tabs: productCategories.map(category => ({
        children: (
          <TestResultPage
            key={category}
            characterAdjectiveItemsData={characterAdjectiveItemsData?.[category]}
            characterData={userBestMatchCharacterByProductCategory?.[category]}
            commentsList={commentsList}
            handleAuthFeatureClick={handleAuthFeatureClick}
            handleSubmitComment={handleSubmitComment}
            handleUpdateWishlistProductList={handleUpdateWishlistProductList}
            isCommentsLoaded={isCommentsLoaded}
            isDesignSetVinhoodExperience={isDesignSetVinhoodExperience}
            isEnableTastePathAddon={isEnableTastePathAddon}
            isLoadingProduct={isLoadingProduct}
            isNewCommentsDataFetching={isNewCommentsDataFetching}
            locale={locale}
            productCategory={category}
            productInstanceData={resultProductInstanceData?.[category]}
            quizData={quizData?.[category]}
            retailerLocationName={retailerLocationName}
            setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
            storeType={retailerStoreType}
            handleProductCardClick={() => {
              MixpanelTracker.events.productClick(
                resultProductInstanceData?.[category],
                false,
                null,
                MP_POSITION_CONTEXT.TEST_RESULT_PAGE,
              );

              handleAuthFeatureClick({ registrationBannerVariant: AUTH_BANNERS.click });
            }}
          />
        ),
        tabCode: category,
        tabIcon: category as TTabIcon,
        tabText: localeProductCategories[category] || category,
      })),
    }),
    [isTestResultDataLoading, isLoadingProduct, resultProductInstanceData],
  );

  return (
    <>
      {isTestResultDataLoading ? (
        <LoadingSpinner />
      ) : (
        <Tabs
          active={tabsData.active}
          gradientVariant="custom-down"
          isHiddenTabs={isDesignSetVinhoodExperience && productCategories.length === 1}
          tabs={tabsData.tabs}
          tabsVariant="autoSpaced"
          onTabClick={tabCode => setActiveTab(tabCode)}
        />
      )}
    </>
  );
};

export default TestResultPageContainer;
