import { TokenResponse } from '@react-oauth/google';
import { FC, useEffect, useState } from 'react';
import { animated, useTransition } from 'react-spring';

import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { IParsedServiceTerm, IRegisterPageServiceTerms } from '@lib/core/serviceTerms/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { EMAIL } from '@lib/tools/shared/helpers/consts';

import LockIcon from '@components/web/src/assets/icons/lock-icon.svg';
import AuthorizationButton from '@components/web/src/atoms/Buttons/SocialButtons/AuthorizationButton/AuthorizationButton';
import Confetti from '@components/web/src/atoms/Confetti/Confetti';
import AuthFormServerError from '@components/web/src/components/Authentication/AuthFormServerError/AuthFormServerError';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import EmailRegistrationForm from '@components/web/src/organisms/Authentication/EmailRegistration/RegistrationEmailForm';
import GoogleLoginButton from '@components/web/src/organisms/Authentication/GoogleLoginButton/GoogleLoginButton';
import { RegistrationFormInputObject } from '@components/web/src/pages/Authentication/AuthFormInterface';
import * as S from '@components/web/src/templates/Login&Registration/AuthenticationPage/styles';

interface IProps {
  productCategory: TProductCategory;
  userCharacterData: TCharacter;
  authError: string;
  isEmailAuth: boolean;
  isSocialAuthTesting?: boolean;
  registerPageServiceTerms: IRegisterPageServiceTerms;
  handleOnServiceTermToggle: (term: IParsedServiceTerm) => void;
  handleFormSubmit: (data: RegistrationFormInputObject) => void;
  handleEmailAuthButtonClick: () => void;
  responseGoogle: (response: TokenResponse) => void;
}

export const AuthenticationPage: FC<IProps> = ({
  productCategory,
  userCharacterData,
  authError,
  isEmailAuth,
  isSocialAuthTesting,
  registerPageServiceTerms,
  handleOnServiceTermToggle,
  handleEmailAuthButtonClick,
  handleFormSubmit,
  responseGoogle,
}) => {
  const { productCategories } = localeCommon;
  const { title, googleBtn, emailBtn, discoverTitle, prefectCategoryForYou } = localeWidget.registrationResultPage;
  const productCategoryText = LocaleUtils.publishedTerms[productCategories[productCategory]?.id];

  const { image: characterImage, name: characterName, character_caption: characterCaption } = userCharacterData;

  const [startAnimation, setStartAnimation] = useState(true);

  useEffect(() => {
    // to hide confetti animation
    const timer = setTimeout(() => setStartAnimation(null), 6500);

    return () => {
      clearTimeout(timer);
      setStartAnimation(null);
    };
  }, [startAnimation]);

  const transitions = useTransition(isEmailAuth, {
    config: {
      duration: 300,
    },
    enter: {
      opacity: 1,
      y: '0%',
    },
    from: { opacity: 0, y: '-10%' },
    leave: { opacity: 0, y: '-8%' },
  });

  return (
    <S.AuthenticationPageContainer isFullWidth direction="column" gap={0}>
      <Flexbox align="center" direction="column" gap={8} padding="30px 16px 136px">
        {startAnimation && <Confetti />}
        <S.LockIcon alt="lock icon" src={LockIcon} />
        <S.CenteredText
          color={STATIC_COLORS.base.black}
          size="body1"
          text={title}
          localeOptions={{
            productCategory,
            productCategoryText,
          }}
        />
        <S.CenteredText
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          size="h3"
          text={`#${characterName}`}
          weight="semibold"
        />
        <S.CenteredText color={STATIC_COLORS.base.black} size="body1" text={characterCaption} weight="medium" />
      </Flexbox>
      <S.ContentWrapper>
        <S.Circle $zIndex={1} />
        <S.Circle $color={STATIC_COLORS.warmGray[50]} $sideOffset={-145} $top={-65} $width={400} />
        <S.Circle $side="right" $zIndex={1} />
        <S.Circle $color={STATIC_COLORS.warmGray[50]} $side="right" $sideOffset={-145} $top={-65} $width={400} />
        <S.Content isFullWidth direction="column" gap={16} margin="-120px 0 0" padding="0 16px 21px">
          <S.CharacterIcon>
            <img alt="icon" src={characterImage} />
            <S.CharacterBackground $backgroundColor={STATIC_COLORS.productColors[productCategory][50]} />
          </S.CharacterIcon>
          <S.CenteredText
            color={STATIC_COLORS.base.black}
            size="body1"
            text={prefectCategoryForYou}
            localeOptions={{
              productCategory,
              productCategoryText,
            }}
          />
          <S.CenteredText
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            size="h4"
            text={discoverTitle}
            weight="semibold"
          />
          <Flexbox isFullWidth direction="column" gap={8}>
            <GoogleLoginButton
              isDisabled={isEmailAuth}
              isHidden={isSocialAuthTesting}
              responseGoogle={responseGoogle}
              text={googleBtn}
            />
            <AuthorizationButton
              handleClick={handleEmailAuthButtonClick}
              text={emailBtn}
              type={EMAIL}
              variant="secondary"
            />
          </Flexbox>
          {transitions((style, isOpen) => (
            <>
              {isOpen && (
                <animated.div style={style}>
                  <EmailRegistrationForm
                    handleFormSubmit={handleFormSubmit}
                    handleOnServiceTermToggle={handleOnServiceTermToggle}
                    registerPageServiceTerms={registerPageServiceTerms}
                  />
                </animated.div>
              )}
            </>
          ))}
          <AuthFormServerError authErrors={authError} />
        </S.Content>
      </S.ContentWrapper>
    </S.AuthenticationPageContainer>
  );
};
