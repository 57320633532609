import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';

type Props = {
  handleIframeCookieServiceTermConfirmation: () => void;
  productCategory: TProductCategory;
  navigateToCookiePolicyTab: () => void;
  serviceTermsListUpdateIsLoading: boolean;
};

const StartPage: FC<Props> = ({
  productCategory,
  handleIframeCookieServiceTermConfirmation,
  navigateToCookiePolicyTab,
  serviceTermsListUpdateIsLoading,
}) => {
  const { productCategoriesPlural } = localeCommon;

  const { publishedTerms } = LocaleUtils;

  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory]?.id];

  const { startPage } = localeWidget;

  const checkOutCookieText = (
    <LocaleFragment
      message={startPage.checkOutCookie}
      insertIntlJSXPartOptions={{
        className: 'sub-title',
        linkId: 'cookie-page',
        linkRender: text => (
          <span
            aria-hidden
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={navigateToCookiePolicyTab}
          >
            {text}
          </span>
        ),
      }}
    />
  );

  return (
    <div className="start-page-container">
      <div className="container-content">
        <p className="title">
          <LocaleFragment
            index={{ productCategory }}
            message={startPage.title}
            options={{ productCategoryPluralText }}
          />
        </p>
        <p className="sub-title">
          <LocaleFragment
            index={{ productCategory }}
            message={startPage.subTitle}
            options={{ productCategoryPluralText }}
          />
        </p>
        {checkOutCookieText}
        <Button
          disabled={serviceTermsListUpdateIsLoading}
          size="sm"
          text={startPage.btnText}
          variant={VH_VARIANTS.LIGHT}
          onClick={handleIframeCookieServiceTermConfirmation}
        />
        {serviceTermsListUpdateIsLoading && <LoadingSpinner variant="spinner" />}
      </div>
    </div>
  );
};

export default StartPage;
