import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { IComment, ICommentHandleSubmit } from '@lib/core/comments/types';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IThirdPartyNewsletterTexts } from '@lib/core/service/types/interface';
import { TProductFeedback, TProductFeedbackValue } from '@lib/core/users/slices/productFeedback';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { PRODUCT_CATEGORY_WINE } from '@lib/tools/shared/helpers/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';

import ProductListComponent from '@components/web/src/components/Catalog/ProductList/ProductList';
import { STATIC_COLORS } from '@components/web/src/foundations';
import NoProductsAvailable from '@components/web/src/organisms/Catalog/NoProductsAvailable/NoProductsAvailable';
import ThirdPartyNewsletter from '@components/web/src/organisms/ThirdPartyNewsletter/ThirdPartyNewsletter';
import * as S from '@components/web/src/pages/widget/Catalog/styles';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import SeeAllProductsButton from '@components/web/src/widget/Buttons/SeeAllProductsButton';
import MatchedProducts from '@components/web/src/widget/Catalog/MatchedProducts';

export interface ILimitedCatalogProps {
  products: TProductInstance[];
  retailerName?: string;
  matchedProducts: TProductInstance[];
  catalogType: string;
  shouldShowDownloadAppCard?: boolean;
  isProductsLoaded: boolean;
  isProductsRequestLoading?: boolean;
  productCategory: TProductCategory;
  storeType: TRetailerLocationStoreType;
  locale: string;
  isEnableLocationMapAddon?: boolean;
  isEnableVusionAddon?: boolean;
  isShowProductLocationAddon?: boolean;
  isEnableTastePathAddon?: boolean;
  feedbackData?: TProductFeedback[];
  wishlistProductInstanceIds: string[];
  isWishlistProductListLoading?: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  isShowThirdPartyNewsletter: boolean;
  handleThirdPartyNewsletter: (value: boolean) => void;
  handleProductsRequest: (args: IHandleProductsRequestParams) => void;
  handleUpdateWishlistProductList?: (productId: string, productName?: string) => void;
  handleUpdateFeedback: (feedback: TProductFeedbackValue, productId: string, productName?: string) => void;
  handleAuthFeatureClick?: () => void;
  isCommentsLoaded?: boolean;
  commentsList?: IComment[];
  isNewCommentsDataFetching?: boolean;
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
  thirdPartyNewsletterTexts: IThirdPartyNewsletterTexts;
}

const LimitedCatalog: FC<ILimitedCatalogProps> = ({
  products,
  isProductsLoaded,
  matchedProducts,
  isProductsRequestLoading,
  retailerName,
  catalogType,
  productCategory = PRODUCT_CATEGORY_WINE,
  storeType,
  locale,
  isEnableLocationMapAddon,
  isEnableVusionAddon,
  isShowProductLocationAddon,
  isEnableTastePathAddon,
  feedbackData = [],
  wishlistProductInstanceIds,
  isWishlistProductListLoading,
  shouldShowDownloadAppCard,
  discoveryQuiz,
  isShowThirdPartyNewsletter,
  handleThirdPartyNewsletter,
  handleProductsRequest,
  handleUpdateWishlistProductList,
  handleUpdateFeedback,
  handleAuthFeatureClick,
  isCommentsLoaded,
  handleSubmitComment,
  setIsNewCommentsDataFetching,
  isNewCommentsDataFetching,
  commentsList,
  thirdPartyNewsletterTexts,
}) => {
  const [isProductsListOpened, setIsProductsListOpened] = useState(true);

  const handleToggleProductsList = () => {
    setIsProductsListOpened(!isProductsListOpened);
  };

  useEffect(() => {
    if (isProductsLoaded && matchedProducts.length) {
      setIsProductsListOpened(false);
    } else {
      setIsProductsListOpened(true);
    }
  }, [isProductsLoaded, matchedProducts]);

  const { productCatalog: productCatalogMessagesWidget } = localeWidget;

  const isMatchedProductsAvailable = !!matchedProducts.length;
  const isProductsAvailable = !!products.length;
  const isBoldProductsListHeadText = !isMatchedProductsAvailable;
  const noProductsAvailable = !isProductsAvailable && !isMatchedProductsAvailable && isProductsLoaded;

  const extraPaddingBottom = isProductsListOpened && matchedProducts.length > 0;

  const isShowSeeAllProductsButton = isMatchedProductsAvailable && isProductsAvailable;
  return (
    <S.ProductCatalogWrapper $isWithExtraPadding={extraPaddingBottom}>
      {noProductsAvailable && <NoProductsAvailable />}

      <>
        {(isProductsRequestLoading || isMatchedProductsAvailable) && (
          <>
            <MatchedProducts
              catalogType={catalogType}
              commentsList={commentsList}
              discoveryQuiz={discoveryQuiz}
              feedbackData={feedbackData}
              handleAuthFeatureClick={handleAuthFeatureClick}
              handleProductsRequest={handleProductsRequest}
              handleSubmitComment={handleSubmitComment}
              handleUpdateFeedback={handleUpdateFeedback}
              handleUpdateWishlistProductList={handleUpdateWishlistProductList}
              isCommentsLoaded={isCommentsLoaded}
              isEnableLocationMapAddon={isEnableLocationMapAddon}
              isEnableTastePathAddon={isEnableTastePathAddon}
              isEnableVusionAddon={isEnableVusionAddon}
              isNewCommentsDataFetching={isNewCommentsDataFetching}
              isProductsRequestLoading={isProductsRequestLoading}
              isShowProductLocationAddon={isShowProductLocationAddon}
              isWishlistProductListLoading={isWishlistProductListLoading}
              locale={locale}
              matchedProducts={matchedProducts}
              productCategory={productCategory}
              setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
              storeType={storeType}
              wishlistProductInstanceIds={wishlistProductInstanceIds}
            />
            {isShowThirdPartyNewsletter && (
              <ThirdPartyNewsletter
                handleThirdPartyNewsletter={handleThirdPartyNewsletter}
                retailerName={retailerName}
                thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
                withMarginTop={shouldShowDownloadAppCard}
              />
            )}
          </>
        )}
        {isShowSeeAllProductsButton && (
          <S.SeeAllProductsButtonWrapper>
            <SeeAllProductsButton handleClick={handleToggleProductsList} isOpenedState={isProductsListOpened} />
          </S.SeeAllProductsButtonWrapper>
        )}
        {isProductsListOpened && isProductsAvailable && (
          <>
            <S.StyledText
              $isBoldProductsListHeadText={isBoldProductsListHeadText}
              color={STATIC_COLORS.base.black}
              fontFamily={isBoldProductsListHeadText ? 'Fraunces' : 'Montserrat'}
              localeIndex={{ productCategory }}
              size={isBoldProductsListHeadText ? 'h5' : 'body1'}
              text={productCatalogMessagesWidget.allProductsByProximity}
              weight="semibold"
            />

            <ProductListComponent
              disablePagination
              commentsList={commentsList}
              discoveryQuiz={discoveryQuiz}
              feedbackData={feedbackData}
              handleAuthFeatureClick={handleAuthFeatureClick}
              handleProductsRequest={handleProductsRequest}
              handleSubmitComment={handleSubmitComment}
              handleThirdPartyNewsletter={handleThirdPartyNewsletter}
              handleUpdateFeedback={handleUpdateFeedback}
              handleUpdateWishlistProductList={handleUpdateWishlistProductList}
              isCommentsLoaded={isCommentsLoaded}
              isEnableLocationMapAddon={isEnableLocationMapAddon}
              isEnableTastePathAddon={isEnableTastePathAddon}
              isEnableVusionAddon={isEnableVusionAddon}
              isMatchedProductsAvailable={isMatchedProductsAvailable}
              isNewCommentsDataFetching={isNewCommentsDataFetching}
              isProductsRequestLoading={!isProductsLoaded}
              isShowProductLocationAddon={isShowProductLocationAddon}
              isShowThirdPartyNewsletter={isShowThirdPartyNewsletter}
              isWishlistProductListLoading={isWishlistProductListLoading}
              itemsCount={products.length}
              locale={locale}
              products={products}
              retailerName={retailerName}
              setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
              shouldShowDownloadAppCard={shouldShowDownloadAppCard}
              storeType={storeType}
              thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
              wishlistProductInstanceIds={wishlistProductInstanceIds}
            />
          </>
        )}
      </>
    </S.ProductCatalogWrapper>
  );
};

export default LimitedCatalog;
