import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useRegistrationBannersData } from '@app/web/src/hooks/useRegistrationBannersData';

import { useUserQuiz } from '@lib/core/quizzes/hooks';
import { QUIZ_TYPE_EXPERT, QUIZ_TYPE_MULTI_PRODUCT, QUIZ_TYPE_TASTE } from '@lib/core/quizzes/utils/consts';
import { useServiceInstance } from '@lib/core/service/hooks/useServiceInstance';
import { setIsTasteIdGuideShown } from '@lib/core/service/slices';
import { actionSetRegisteredUserNotFinishedOnboarding } from '@lib/core/service/slices/technical/technical';
import { prependBasename } from '@lib/core/service/utils';
import { useServiceTerms } from '@lib/core/serviceTerms/hooks/useServiceTerms';
import { IParsedServiceTerm, IRegisterPageServiceTerms } from '@lib/core/serviceTerms/types';
import { useUser } from '@lib/core/users/hooks';
import {
  actionRegister,
  facebookLoginCallback,
  googleLoginCallback,
  resetAuthError,
} from '@lib/core/users/slices/auth';
import RouteUtils from '@lib/tools/routes';
import { REGISTRATION_URL_PARAM, START_EMAIL_REGISTRATION_URL_PARAM } from '@lib/tools/shared/auths/helpers/consts';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

import { RegistrationFormInputObject } from '@components/web/src/pages/Authentication/AuthFormInterface';
import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import RegistrationPage from '@components/web/src/templates/Login&Registration/Registration/RegistrationPage/RegistrationPage';

const RegistrationContainer: FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const { isServiceInstanceFeatureEnabled } = useServiceInstance();
  const { userQuizId, userQuizType } = useUserQuiz();
  const { isUserAuthenticated, isUserDataLoading, isUserHasAnyCharacter, authError, isAuthDataLoading } = useUser();
  const { isDesignSetVinhoodApp, isDesignSetVinhoodExperience } = useRetailerDesignSet();
  const { isHideSkipAndGoToProductsAddon } = useAddons();
  const isSocialAuthTesting = searchParams.get('is_social_test') === 'true';
  const {
    serviceTermsByCategory: { policy, marketing },
    updateServiceTerms,
  } = useServiceTerms();

  const registrationBannersData = useRegistrationBannersData();

  const [registerPageServiceTerms, setRegisterPageServiceTerms] = useState<IRegisterPageServiceTerms>({
    marketing,
    policy,
  });
  useEffect(() => {
    setRegisterPageServiceTerms({ marketing, policy });
  }, [policy, marketing]);

  const handleOnServiceTermToggle = ({ category }: IParsedServiceTerm) => {
    setRegisterPageServiceTerms({
      ...registerPageServiceTerms,
      [category]: {
        ...registerPageServiceTerms[category],
        is_selected: !registerPageServiceTerms[category].is_selected,
      },
    });
  };

  const isEnabledAutoOpenEmailForm = searchParams.get(REGISTRATION_URL_PARAM) === START_EMAIL_REGISTRATION_URL_PARAM;

  const [isEmailAuthorization, setIsEmailAuthorization] = useState(isEnabledAutoOpenEmailForm);

  useEffect(() => {
    if (isDesignSetVinhoodApp && !userQuizId) {
      navigate(prependBasename(PAGES.vinhood.quiz.chooseTaste));
    } else if (isDesignSetVinhoodExperience && isUserAuthenticated) {
      if (userQuizId && [QUIZ_TYPE_MULTI_PRODUCT, QUIZ_TYPE_TASTE, QUIZ_TYPE_EXPERT].includes(userQuizType)) {
        if (isUserHasAnyCharacter) {
          navigate(prependBasename(PAGES.vinhood.catalog));
        }
      } else {
        navigate(prependBasename(PAGES.vinhood.catalog));
      }
    }
  }, [userQuizId, isUserAuthenticated, isAuthDataLoading, isUserHasAnyCharacter]);

  useEffect(() => {
    return () => {
      dispatch(resetAuthError());
    };
  }, []);

  const responseApple = response => console.info(response);

  const handleTasteIdGuideShownStatus = () => dispatch(setIsTasteIdGuideShown(false));

  const handleFormSubmit = async (data: RegistrationFormInputObject) => {
    const { email, password, lastName, name } = data;

    await dispatch(
      actionRegister({
        email,
        first_name: name,
        last_name: lastName,
        password,
      }),
    ).then(() => {
      dispatch(actionSetRegisteredUserNotFinishedOnboarding(true));
      updateServiceTerms(Object.values(registerPageServiceTerms));
      handleTasteIdGuideShownStatus();
    });
  };

  const onEmailAuthButtonClick = () => {
    setIsEmailAuthorization(state => !state);
    dispatch(resetAuthError());
  };

  const navigateToCatalog = () => {
    if (isServiceInstanceFeatureEnabled) {
      RouteUtils.navigateToWidgetServiceInstance(PAGES.vinhood.catalog);
      return;
    }

    navigate(prependBasename(PAGES.vinhood.catalog));
  };

  const navigateToLoginPage = () => navigate(prependBasename(PAGES.vinhood.signIn), { state: { ...location?.state } });

  const onBackButtonClick = () => navigate(-1);

  return (
    <>
      {isUserDataLoading || isUserAuthenticated ? (
        <LoadingSpinner />
      ) : (
        <RegistrationPage
          authErrors={authError}
          handleFormSubmit={handleFormSubmit}
          handleOnServiceTermToggle={handleOnServiceTermToggle}
          isDesignSetVinhoodExperience={isDesignSetVinhoodExperience}
          isEmailAuthorization={isEmailAuthorization}
          isHideSkipAndGoToProductsAddon={isHideSkipAndGoToProductsAddon}
          isServiceInstanceFeatureEnabled={isServiceInstanceFeatureEnabled}
          isSocialAuthTesting={isSocialAuthTesting}
          navigateToCatalog={navigateToCatalog}
          navigateToLoginPage={navigateToLoginPage}
          registerPageServiceTerms={registerPageServiceTerms}
          registrationBannersData={registrationBannersData}
          responseApple={responseApple}
          responseFacebook={facebookLoginCallback(dispatch, true)}
          responseGoogle={googleLoginCallback(dispatch, true)}
          onBackButtonClick={onBackButtonClick}
          onEmailAuthButtonClick={onEmailAuthButtonClick}
        />
      )}
    </>
  );
};

export default RegistrationContainer;
