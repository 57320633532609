import styled from 'styled-components';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import Button from '@components/web/src/atoms/Buttons/Button';
import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const TasteIdPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ProfileProgressBarWrapper = styled.div`
  padding: 8px 16px 0;
  background-color: ${STATIC_COLORS.base.white};
  z-index: 2;
`;

export const CharacterDetailsSection = styled(SwiperHOC)`
  padding-left: 16px;
  margin-top: -70px;

  .swiper-wrapper {
    > *:last-child {
      margin-inline-end: 16px;
    }
  }
`;

export const ProductJournalSection = styled(Flexbox)`
  padding-block: 30px;

  .swiper-wrapper {
    padding-inline: 16px;

    > *:last-child {
      margin-right: 0;
    }
  }
`;

export const ProductJournalHeader = styled(Flexbox)`
  padding-inline: 16px;
`;

export const ProductViewAllCard = styled(Flexbox)`
  padding-inline: 16px;
  min-width: 210px;
  height: 100%;
`;

export const ViewAllCTA = styled(Button)`
  border-radius: 28px;
`;
export const BannerWrapper = styled.div`
  position: sticky;
  bottom: 54px;
  left: 0;
  z-index: 9999;
`;

export const CharacterDetailsBackButton = styled(BackButton)`
  width: 100%;
  z-index: 12;
  background: ${STATIC_COLORS.warmGray[100]};
  padding: 8px 16px;
`;
