import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { prependBasename } from '@lib/core/service/utils';
import { useServiceTerms } from '@lib/core/serviceTerms/hooks/useServiceTerms';
import { IParsedServiceTerm } from '@lib/core/serviceTerms/types';
import { useUser } from '@lib/core/users/hooks/useUser';
import { PAGES } from '@lib/tools/views/urls';

import { IModalOption } from '@components/web/src/app/Modals/modalTypes';
import GeneralServiceTermsPage from '@components/web/src/templates/LegalPages/GeneralServiceTermsPage/GeneralServiceTermsPage';

const GeneralServiceTermsContainer = () => {
  const navigate = useNavigate();

  const {
    updateServiceTerms,
    serviceTermsListUpdateIsLoading,
    serviceTermsByCategory: { cookie, profiling, marketing },
  } = useServiceTerms();

  const { isUserAuthenticated } = useUser();
  const [generalServiceTermsState, setGeneralServiceTermsState] = useState([]);

  useEffect(() => {
    if (!serviceTermsListUpdateIsLoading) {
      const terms = [cookie, profiling, ...(isUserAuthenticated ? [marketing] : [])].map(term => ({
        ...term,
        is_selected: term?.is_always_active || term?.is_selected,
      }));

      setGeneralServiceTermsState(terms);
    }
  }, [cookie, profiling, marketing, isUserAuthenticated]);

  const handleGoBackClick = () => {
    navigate(-1);
  };

  const handleSaveGeneralServiceTerms = () => {
    updateServiceTerms(generalServiceTermsState).then(() => navigate(prependBasename(PAGES.vinhood.home)));
  };

  const handleOnServiceTermToggle = ({ category }: IParsedServiceTerm | IModalOption) => {
    setGeneralServiceTermsState(
      generalServiceTermsState.map(term =>
        term?.category === category ? { ...term, is_selected: !term?.is_selected } : term,
      ),
    );
  };

  return (
    <GeneralServiceTermsPage
      generalServiceTermsState={generalServiceTermsState}
      handleGoBack={handleGoBackClick}
      handleOnServiceTermToggle={handleOnServiceTermToggle}
      handleSaveGeneralServiceTerms={handleSaveGeneralServiceTerms}
      isLoading={serviceTermsListUpdateIsLoading}
    />
  );
};

export default GeneralServiceTermsContainer;
