import { Dispatch, FC, SetStateAction, useState } from 'react';

import { IComment, ICommentHandleSubmit } from '@lib/core/comments/types';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IThirdPartyNewsletterTexts } from '@lib/core/service/types/interface';
import { TProductFeedback, TProductFeedbackValue } from '@lib/core/users/slices/productFeedback';
import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import { TProductCatalogOrdering } from '@lib/tools/filterManager/slices/productFilter';
import FilterContainer from '@lib/tools/filterManager/views/containers/FilterContainer';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { PRODUCT_CATEGORY_WINE } from '@lib/tools/shared/helpers/consts';
import { IFilterProps, IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';

import ProductListComponent from '@components/web/src/components/Catalog/ProductList/ProductList';
import SortingSection from '@components/web/src/components/Catalog/SortingSection/SortingSection';
import { STATIC_COLORS } from '@components/web/src/foundations';
import NoProductsAvailable from '@components/web/src/organisms/Catalog/NoProductsAvailable/NoProductsAvailable';
import ThirdPartyNewsletter from '@components/web/src/organisms/ThirdPartyNewsletter/ThirdPartyNewsletter';
import * as S from '@components/web/src/pages/widget/Catalog/styles';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import SeeAllProductsButton from '@components/web/src/widget/Buttons/SeeAllProductsButton';
import MatchedProducts from '@components/web/src/widget/Catalog/MatchedProducts';

export interface IExtendedCatalogProps {
  handleProductsRequest: (args: IHandleProductsRequestParams) => void;
  products: TProductInstance[];
  matchedProducts: TProductInstance[];
  catalogType: string;
  retailerName?: string;
  isProductsLoaded: boolean;
  isProductsRequestLoading: boolean;
  shouldShowDownloadAppCard?: boolean;
  productCategory: TProductCategory;
  filterProps?: IFilterProps;
  itemsCount?: number;
  storeType: TRetailerLocationStoreType;
  locale: string;
  withOpenedProductList?: boolean;
  isEnableLocationMapAddon?: boolean;
  isEnableVusionAddon?: boolean;
  isShowProductLocationAddon?: boolean;
  feedbackData?: TProductFeedback[];
  isFeedbackLoading?: boolean;
  isWishlistProductListLoading?: boolean;
  wishlistProductInstanceIds: string[];
  isEnableTastePathAddon: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  isChangeOrderingEnabled?: boolean;
  activeOrdering?: TProductCatalogOrdering;
  isShowThirdPartyNewsletter: boolean;
  handleThirdPartyNewsletter: (value: boolean) => void;
  handleChangeOrdering?: (order: TProductCatalogOrdering) => void;
  handleUpdateWishlistProductList: (productId: string, productName?: string) => void;
  handleUpdateFeedback?: (feedback: TProductFeedbackValue, productId: string, productName?: string) => void;
  handleAuthFeatureClick?: () => void;
  isCommentsLoaded?: boolean;
  commentsList?: IComment[];
  isNewCommentsDataFetching?: boolean;
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
  thirdPartyNewsletterTexts: IThirdPartyNewsletterTexts;
}

const ExtendedCatalog: FC<IExtendedCatalogProps> = ({
  thirdPartyNewsletterTexts,
  products,
  isProductsLoaded,
  matchedProducts,
  catalogType,
  storeType,
  retailerName,
  productCategory = PRODUCT_CATEGORY_WINE,
  itemsCount,
  shouldShowDownloadAppCard,
  filterProps,
  isProductsRequestLoading,
  locale,
  withOpenedProductList = false,
  isEnableLocationMapAddon,
  isEnableVusionAddon,
  isShowProductLocationAddon,
  isEnableTastePathAddon,
  feedbackData = [],
  wishlistProductInstanceIds,
  isWishlistProductListLoading,
  discoveryQuiz,
  activeOrdering,
  isChangeOrderingEnabled = false,
  isShowThirdPartyNewsletter,
  handleThirdPartyNewsletter,
  handleChangeOrdering,
  handleProductsRequest,
  handleUpdateWishlistProductList,
  handleUpdateFeedback,
  handleAuthFeatureClick,
  isCommentsLoaded,
  commentsList,
  handleSubmitComment,
  setIsNewCommentsDataFetching,
  isNewCommentsDataFetching,
}) => {
  const { isFilterOpened } = useCatalogFilter();
  const [isProductsListOpened, setIsProductsListOpened] = useState(withOpenedProductList);
  const isMatchedProductsAvailable = !!matchedProducts.length;
  const isProductsAvailable = !!products.length;
  const noProductsAvailable =
    !isProductsAvailable && !isMatchedProductsAvailable && isProductsLoaded && !isProductsRequestLoading;

  const handleToggleProductsList = () => {
    setIsProductsListOpened(!isProductsListOpened);
  };

  const { productCatalog: productCatalogMessagesWidget } = localeWidget;
  const isShowSeeAllProductsButton = isMatchedProductsAvailable && isProductsAvailable;

  return (
    <S.ProductCatalogWrapper $isFilterOpened={isFilterOpened}>
      {noProductsAvailable && <NoProductsAvailable />}
      {!isFilterOpened && (
        <>
          {(isProductsRequestLoading || isMatchedProductsAvailable) && (
            <>
              <MatchedProducts
                catalogType={catalogType}
                commentsList={commentsList}
                discoveryQuiz={discoveryQuiz}
                feedbackData={feedbackData}
                handleAuthFeatureClick={handleAuthFeatureClick}
                handleProductsRequest={handleProductsRequest}
                handleSubmitComment={handleSubmitComment}
                handleUpdateFeedback={handleUpdateFeedback}
                handleUpdateWishlistProductList={handleUpdateWishlistProductList}
                isCommentsLoaded={isCommentsLoaded}
                isEnableLocationMapAddon={isEnableLocationMapAddon}
                isEnableTastePathAddon={isEnableTastePathAddon}
                isEnableVusionAddon={isEnableVusionAddon}
                isNewCommentsDataFetching={isNewCommentsDataFetching}
                isProductsRequestLoading={isProductsRequestLoading}
                isShowProductLocationAddon={isShowProductLocationAddon}
                isWishlistProductListLoading={isWishlistProductListLoading}
                locale={locale}
                matchedProducts={matchedProducts}
                productCategory={productCategory}
                setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
                storeType={storeType}
                wishlistProductInstanceIds={wishlistProductInstanceIds}
              />
              {isShowThirdPartyNewsletter && (
                <ThirdPartyNewsletter
                  handleThirdPartyNewsletter={handleThirdPartyNewsletter}
                  retailerName={retailerName}
                  thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
                  withMarginTop={shouldShowDownloadAppCard}
                />
              )}
            </>
          )}

          {isShowSeeAllProductsButton && (
            <S.SeeAllProductsButtonWrapper>
              <SeeAllProductsButton handleClick={handleToggleProductsList} isOpenedState={isProductsListOpened} />
            </S.SeeAllProductsButtonWrapper>
          )}
          {isProductsListOpened && isProductsAvailable && (
            <S.StyledText
              color={STATIC_COLORS.base.black}
              size="body1"
              text={productCatalogMessagesWidget.soManyProducts}
              weight="semibold"
            />
          )}
        </>
      )}

      <FilterContainer
        {...filterProps}
        isSearchEnabled
        handleApply={handleProductsRequest}
        isLayoutHidden={!isProductsListOpened || isProductsRequestLoading}
        productCategory={productCategory}
      />

      {!isFilterOpened && isProductsListOpened && (
        <>
          <SortingSection
            activeOrdering={activeOrdering}
            handleChangeOrdering={handleChangeOrdering}
            isChangeOrderingEnabled={isChangeOrderingEnabled}
            isProductsRequestLoading={isProductsRequestLoading}
            itemsCount={itemsCount}
          />
          <ProductListComponent
            commentsList={commentsList}
            disablePagination={false}
            discoveryQuiz={discoveryQuiz}
            feedbackData={feedbackData}
            handleAuthFeatureClick={handleAuthFeatureClick}
            handleProductsRequest={handleProductsRequest}
            handleSubmitComment={handleSubmitComment}
            handleThirdPartyNewsletter={handleThirdPartyNewsletter}
            handleUpdateFeedback={handleUpdateFeedback}
            handleUpdateWishlistProductList={handleUpdateWishlistProductList}
            isCommentsLoaded={isCommentsLoaded}
            isEnableLocationMapAddon={isEnableLocationMapAddon}
            isEnableTastePathAddon={isEnableTastePathAddon}
            isEnableVusionAddon={isEnableVusionAddon}
            isMatchedProductsAvailable={isMatchedProductsAvailable}
            isNewCommentsDataFetching={isNewCommentsDataFetching}
            isProductsRequestLoading={isProductsRequestLoading}
            isShowProductLocationAddon={isShowProductLocationAddon}
            isShowThirdPartyNewsletter={isShowThirdPartyNewsletter}
            isWishlistProductListLoading={isWishlistProductListLoading}
            itemsCount={itemsCount}
            locale={locale}
            products={products}
            retailerName={retailerName}
            setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
            shouldShowDownloadAppCard={shouldShowDownloadAppCard}
            storeType={storeType}
            thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
            wishlistProductInstanceIds={wishlistProductInstanceIds}
          />
        </>
      )}
    </S.ProductCatalogWrapper>
  );
};

export default ExtendedCatalog;
