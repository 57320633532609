import { useNavigate } from 'react-router-dom';

import PrivacyPolicyPage from '@components/web/src/templates/LegalPages/PrivacyPolicyPage/PrivacyPolicyPage';
import { StyledBackButton } from '@components/web/src/templates/LegalPages/PrivacyPolicyPage/styles';

const PrivacyPolicyContainer = () => {
  const navigate = useNavigate();

  return (
    <>
      <StyledBackButton handleClick={() => navigate(-1)} />
      <PrivacyPolicyPage />;
    </>
  );
};

export default PrivacyPolicyContainer;
