import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useHomeData } from '@app/web/src/hooks/useHomeData';

import { useUserQuiz } from '@lib/core/quizzes/hooks';
import { useRetailerLocation } from '@lib/core/retailers/hooks';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import RouteUtils from '@lib/tools/routes';
import { removeUrlParams } from '@lib/tools/shared/helpers';
import { PRODUCT_CATEGORY_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { resetTastePathData } from '@lib/tools/tastePathProducts/slices';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import { TTabIcon } from '@components/web/src/atoms/Tabs/TabButton/TabButton';
import Tabs, { ITabs } from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import HomePage from '@components/web/src/templates/Start&HomePages/Widget/HomePage/HomePage';

const HomeContainer: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const { isLoading, isRecipeQuizAvailable, pageVariants, charactersData, productCategories } = useHomeData();

  const { locale, shouldShowLanguageSwitcher } = useApp();
  const { retailerLocationName } = useRetailerLocation();
  const { isExternalCharacterPage } = useAddons();
  const { userQuizRetailerCharacterRedirectUrls } = useUserQuiz();
  const { userFirstName, isUserAuthenticated, handleResetUserData } = useUser();
  const { isAuthenticatedRetailerAccess, retailerLanguages, isAnonymousRetailerAccess } = useRetailer();

  const [activeTab] = useState(searchParams.get(PRODUCT_CATEGORY_URL_PARAM));

  useEffect(() => {
    if (activeTab) {
      removeUrlParams({ keys: [PRODUCT_CATEGORY_URL_PARAM] });
    }
  }, [activeTab]);

  const tabsData: ITabs = useMemo(() => {
    const active = activeTab || productCategories[0];

    const tabs = productCategories.map(category => {
      const { identifier = '' } = charactersData?.[category][0] || {};

      const retailerCharacterRedirectURL = userQuizRetailerCharacterRedirectUrls?.[identifier] || '';
      const handleNavigation = () => {
        const shouldNavigateToExternalPage = isExternalCharacterPage && retailerCharacterRedirectURL?.length > 3;
        const shouldNavigateToRegistrationPage = !isUserAuthenticated && isAuthenticatedRetailerAccess;
        if (shouldNavigateToExternalPage) {
          RouteUtils.redirectExternalCharacterPage(retailerCharacterRedirectURL);
          return;
        }

        if (shouldNavigateToRegistrationPage) {
          navigate(prependBasename(PAGES.vinhood.registration, { [PRODUCT_CATEGORY_URL_PARAM]: category }));
          return;
        }

        MixpanelTracker.events.productCatalogClick();
        navigate(prependBasename(PAGES.vinhood.catalog, { [PRODUCT_CATEGORY_URL_PARAM]: category }));
      };

      const navigateToQuizSelectionPage = () => {
        MixpanelTracker.events.startTasteTestClick();

        if (!isUserAuthenticated) {
          navigate(prependBasename(PAGES.vinhood.ageAndGender, { [PRODUCT_CATEGORY_URL_PARAM]: category }));
        } else {
          navigate(prependBasename(PAGES.vinhood.quiz.chooseExpert, { [PRODUCT_CATEGORY_URL_PARAM]: category }));
        }
      };

      const navigateToRecipePage = () => {
        MixpanelTracker.events.startFoodPairingClick();
        navigate(prependBasename(PAGES.vinhood.recipeSearch, { [PRODUCT_CATEGORY_URL_PARAM]: category }));
      };

      const navigateToLoginPage = () =>
        navigate(prependBasename(PAGES.vinhood.signIn, { [PRODUCT_CATEGORY_URL_PARAM]: category }), {
          state: { isFromWidgetHomePage: true },
        });

      return {
        children: (
          <HomePage
            key={category}
            charactersData={charactersData?.[category]}
            handleNavigation={handleNavigation}
            isAnonymousRetailerAccess={isAnonymousRetailerAccess}
            isRecipeQuizAvailable={isRecipeQuizAvailable}
            locale={locale}
            navigateToLoginPage={navigateToLoginPage}
            navigateToQuizSelectionPage={navigateToQuizSelectionPage}
            navigateToRecipePage={navigateToRecipePage}
            productCategory={category}
            retailerLanguages={retailerLanguages}
            retailerLocationName={retailerLocationName}
            shouldShowLanguageSwitcher={shouldShowLanguageSwitcher}
            userFirstName={userFirstName}
            variant={pageVariants[category]}
            handleResetUserData={() => {
              handleResetUserData();
              dispatch(resetTastePathData());
            }}
          />
        ),
        tabCode: category,
        tabIcon: category as TTabIcon,
        tabText: localeCommon.productCategories[category] || category,
      };
    });

    return { active, tabs };
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner variant="spinner" />
      ) : (
        <Tabs
          active={tabsData.active}
          gradientVariant="custom-down"
          isHiddenTabs={productCategories?.length <= 1}
          tabs={tabsData.tabs}
          tabsVariant="autoSpaced"
        />
      )}
    </>
  );
};

export default HomeContainer;
